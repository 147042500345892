<template>
  <div @click="$emit('click')" class="inline-flex items-center">
    <feather-icon :badge="badge" v-if="iconLibrary === 'feather'" :icon="iconName" :svgClasses="`${iconClass}`"></feather-icon>
    <span v-if="iconLibrary === 'phosphor'" :class="`ph ph-${iconName} ${iconClass}`"></span>
    <span v-if="iconLibrary === 'material'" :class="`material-symbols-rounded ${iconClass}`">{{ iconName }}</span>
  </div>
</template>

<script>
import FeatherIcon from '../../components/FeatherIcon.vue'

export default {
  name: 'shipblu-icon',
  props: ['iconLibrary', 'iconName', 'badge', 'iconClass'],
  components: { FeatherIcon }
}
</script>